import * as React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import CompanyInfo from '../components/CompanyInfo'
import Text from '../components/Text'
import Columns from '../components/Columns/Columns'
import Cta from '../components/Cta'
import MemberTestimonial from '../components/MemberTestimonial'

export const query = graphql`
  query ($slug: String!) {
    caseStudiesJson(slug: { eq: $slug }) {
      title
      hero {
        type
        subtitle
        description
        image {
          publicURL
        }
      }
      companyInfo {
        slides {
          name
          url
          location
          type
          employees
          productionFocus
          membership
          memberSince
          logo {
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED, quality: 100)
            }
          }
          items {
            title
            description
            icon {
              publicURL
            }
          }
        }
      }
      text {
        title
        description
        backgroundColor
        textColor
        skipToResults
      }
      columns1 {
        direction
        sizes
        mode
        illustration {
          alt
          src {
            publicURL
          }
        }
        text {
          title
          description
        }
      }
      columns2 {
        direction
        sizes
        mode
        illustration {
          alt
          src {
            publicURL
          }
        }
        text {
          title
          description
        }
      }
      cta {
        id
        subhead
        title
        description
        buttons {
          text
          href
          variant
          color
        }
      }
      columns3 {
        direction
        sizes
        mode
        illustration {
          alt
          src {
            publicURL
          }
        }
        text {
          title
          description
        }
      }
      memberTestimonial {
        card {
          avatar {
            src {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 241, height: 240, quality: 95, placeholder: BLURRED)
              }
            }
            alt
          }
          description
          name
          position
          cta
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const CaseStudyTemplate = ({ data }) => {
  const caseStudy = data.caseStudiesJson
  return (
    <Layout>
      <Hero content={caseStudy.hero} />
      <CompanyInfo content={caseStudy.companyInfo} />
      <Text content={caseStudy.text} />
      <Columns content={caseStudy.columns1} />
      <Columns content={caseStudy.columns2} />
      <Cta content={caseStudy.cta} />
      <Columns content={caseStudy.columns3} />
      <MemberTestimonial content={caseStudy.memberTestimonial} />
    </Layout>
  )
}

export const Head = ({ data, location }) => (
  <Seo title={data.caseStudiesJson.title} description={data.caseStudiesJson.hero.title} pathname={location.pathname} />
)

export default CaseStudyTemplate
